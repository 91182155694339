<template>
    <div class="social-wrapper">
        <div class="social">
            <svg class="social-close" @click="closeSocialPopup" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
            <h2>Partagez votre profil</h2>
            <div class="boutons-sociaux">
              <a href="whatsapp://send" data-text="Je suis XXXXX. Gastronomaniac, veggie gourmand, street lover ? Vous aussi découvrez votre profil Foodista pour accorder le vin avec vos envies sur food-et-vin.com" data-href="https://jeu-quiz-vin.netlify.app/" class="wa_btn wa_btn_l" style="display:none">Quel mangeur êtes vous ?</a>
                <ul>
                    <li>
                    <ShareNetwork
                        network="facebook"
                        :url="url"
                        :title="title"
                        :description="description"
                        :quote="title+' '+description"
                        :media="media"
                        :hashtags="hashtags"
                        @open="open"
                      >
                      <img src="/facebook.svg">
                    </ShareNetwork>
                    </li>
                    <li>
                      <ShareNetwork
                        network="whatsapp"
                        :url="url"
                        :title="title"
                        :description="description"
                        :media="media"
                        :hashtags="hashtags"
                        @open="open"
                     >
                     <img src="/whatsapp.svg">
                     </ShareNetwork>
                    </li>
                    <li>
                      <ShareNetwork
                        network="twitter"
                        url="food-et-vin.com"
                        :title="twitter"
                        :hashtags="hashtags"
                        @open="open"
                        >
                        <img src="/twitter.svg">
                      </ShareNetwork>                      
                    </li>                                        
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import VueSocialSharing from 'vue-social-sharing'
 Vue.use(VueSocialSharing);

export default {
  data() {
    let shareParam = this.$parent.profil;
    if(shareParam == 'viandard') {
      shareParam = 'bbq-master'
    }
    let ascendant = this.$parent.ascendant;
    if(ascendant == 'viandard') {
      ascendant = 'bbq-master'
    }
    const imgTwitter = {
      'bbq-master': "pic.twitter.com/NzBWWDqrHz",
      'traditionista': "pic.twitter.com/OqIP98UceL",
      'veggie-gourmand': "pic.twitter.com/QsPYwPbnI1",
      'aperitivore': "pic.twitter.com/jG2yDzCx46",
      'street-lover': "pic.twitter.com/h6r3emoYJV",
      'bec-sucre':    "pic.twitter.com/SxEnFN1agt",
      'gastronomaniac': "pic.twitter.com/au5wXSdiHM",
    }
    return {
      profil : undefined,
      url : "https://quiz.food-et-vin.com/" + shareParam + ".html",
      title : "Je suis "+shareParam+" ascendant "+this.$parent.ascendant+".",
      description : "Gastronomaniac, veggie gourmand, street lover ? Vous aussi découvrez votre profil Foodista pour accorder le vin avec vos envies",
      twitter: "Je suis "+shareParam+" ascendant "+this.$parent.ascendant+". Vous aussi découvrez votre profil Foodista pour accorder le vin avec vos envies. " + imgTwitter[shareParam],
      media : "https://food-et-vin.com/images/share/"+this.$parent.profil+'.png',
      hashtags : "FoodEtVin",
    }
   },
   mounted() {
      
   },
   methods : {
      open(network) {
        gtag('event', 'share', {
          'event_category': 'share',
          'event_label': network,
        });
      },
      closeSocialPopup : function () {
        document
            .querySelector(".social-wrapper")
            .classList.remove("popupactive");          
        document
            .querySelector(".social-wrapper")
            .classList.add("popupclose");
        },
      openSocialPopup : function () {
        gtag('event', 'open_share_menu', {
          'event_category': 'share',
        });
        document
            .querySelector(".social-wrapper")
            .classList.remove("popupclose");             
        document
            .querySelector(".social-wrapper")
            .classList.add("popupactive");
        }        
    }
}
</script>
<style lang="scss" >
@import "../scss/_variables.scss";

.boutons-sociaux {
    ul {
        list-style-type: none;
        margin:0;
        padding:0;
        width:100%;
        text-align: center;
        margin-top:15px;
        display:flex;

        li {
           cursor: pointer;
           width:60px;
        }
    }
}

.social-wrapper {
  position: fixed;
  width:220px;
  min-height: 155px;
  top:140px;
  right:-300px;
  background-color:white;
  border-radius : 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index:4;
}

.popupactive {
  -webkit-animation: opensocial 1s 1 forwards;
  -moz-animation: opensocial 1s 1 forwards;
  -o-animation: opensocial 1s 1 forwards;
  animation: opensocial 1s 1 forwards;
}

.popupclose {
  -webkit-animation: closesocial 1s 1 forwards;
  -moz-animation: closesocial 1s 1 forwards;
  -o-animation: closesocial 1s 1 forwards;
  animation: closesocial 1s 1 forwards;
}

.social-close {
    cursor: pointer;
    position: absolute;
    right:10px;
    top:10px;
}

.social {
  padding:20px;

  h2 {
    font-size:25px;
    line-height: 38px;
    font-family: $font-extra;
    font-weight: bold;
    font-style: italic;   
    text-align:center; 
    width:100%;
    margin-left:-6px;
  }
}


@-webkit-keyframes closesocial {
  0% {
    right: 30px;
  }
  100% {
    right: -300px;
  }
}
@-webkit-keyframes opensocial {
  0% {
    right: -300px;
  }
  100% {
    right: 30px;
  }
}
.boutons-sociaux img {
  max-height: 35px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>